import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tw from 'twin.macro'

import { ArticleItem } from '../../pages/blog'

type RenderCallbackArgs = {
  filteredItems: ArticleItem[]
}

type FilterLayoutProps = {
  children: (args: RenderCallbackArgs) => React.ReactNode
  items: ArticleItem[]
}

const FilterLayout = ({ children, items }: FilterLayoutProps) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filteredItems, setFilteredItems] = useState<ArticleItem[]>(items)

  const categories = Array.from(
    new Set(items.map((item: ArticleItem) => item.category)),
  )

  const handleCategoryChange = (category: string | null) => {
    setSelectedCategory(category)

    const filtered = category
      ? items.filter((item: ArticleItem) => item.category === category)
      : items

    setFilteredItems(filtered)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase()
    setSearchTerm(query)
    handleCategoryChange(null)

    const filtered = items.filter(
      (item) =>
        item.title.toLowerCase().includes(query) ||
        item.category.toLowerCase().includes(query) ||
        item.introduction.toLowerCase().includes(query),
    )

    setFilteredItems(filtered)
  }

  useEffect(() => {
    handleCategoryChange(selectedCategory)
  }, [items, selectedCategory])

  return (
    <>
      <div tw="mt-16 mb-16 relative max-w-screen-xl mx-auto px-4">
        <div tw="flex items-center space-x-5 bg-white w-full max-w-md shadow-sm border border-gray-300 rounded-sm">
          <FontAwesomeIcon
            icon={['fal', 'magnifying-glass']}
            tw="pl-4 py-2 text-base text-gray-500"
          />
          <input
            placeholder="Search"
            onChange={handleSearch}
            value={searchTerm}
            tw="bg-transparent text-base block px-0 py-2 w-full border-0 placeholder:(text-base text-gray-500) focus:(border-0 ring-0) focus-visible:(border-0 ring-0 outline-none)"
          />
        </div>
      </div>
      <div
        tw="relative max-w-screen-xl mx-auto px-4 flex items-stretch"
        className="scroll-snap-x"
      >
        <div
          tw="justify-start items-center flex"
          className="scroll-snap-member"
        >
          <button
            tw="h-12 text-gray-500 text-2xl font-thin border-b-2 border-gray-300 border-solid px-5 hover:(border-black text-black)"
            css={[selectedCategory === null && tw`!border-black text-black`]}
            onClick={() => handleCategoryChange(null)}
          >
            All
          </button>
        </div>

        {categories.map((category) => (
          <div
            key={category}
            tw="justify-start items-center flex shrink-0"
            className="scroll-snap-member"
          >
            <button
              tw="h-12 text-gray-500 text-2xl font-thin border-b-2 border-gray-300 border-solid px-5 hover:(border-black text-black)"
              css={[
                selectedCategory === category && tw`!border-black !text-black`,
              ]}
              onClick={() => handleCategoryChange(category ?? null)}
            >
              {category}
            </button>
          </div>
        ))}
      </div>
      <div>{children({ filteredItems })}</div>
    </>
  )
}

export default FilterLayout
